import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../components/Layout"
import Seo from "../components/Seo"

const GetNotifiedSection = () => {
  return(
    <section className="mt-5 lg:mt-20">
      <div className="flex flex-col mx-4 lg:max-w-screen-md lg:mx-auto">
        <h1 className="mb-4 text-4xl font-extrabold lg:text-5xl text-relay-text">Seamless communication</h1>
        <p className="text-base font-semibold lg:text-2xl">Timely notifications for maximum use of the charging gear.</p>
        <p className="text-base font-semibold lg:text-2xl">Easily report faults and concerns to the destination staff.</p>
      </div>
      <div className="flex flex-col max-w-full mt-8 lg:max-w-screen-lg lg:mx-auto">
        <StaticImage 
          placeholder="tracedSVG"
          src="../images/notification.png" 
          alt="Your destination with peace of mind for vehicle charging"/>
      </div>
      <div className="py-10 text-black bg-relay-blue-background lg:py-14">
        <section className="flex flex-col max-w-screen-lg mx-4 lg:mx-auto lg:flex-row">
            <div className="mb-8 lg:w-1/3 lg:mr-8 lg:mb-0">
                <h2 className="text-3xl font-bold text-relay-blue-primary">Charger availability</h2>
                <p className="my-2 text-base-lg">
                  Put your name up on our wait list and we will keep you updated as the charger
                  becomes available so you don't have to keep following up on it.
                </p>
            </div>
            <div className="mb-8 lg:w-1/3 lg:mr-8 lg:mb-0">
              <h2 className="text-3xl font-bold text-relay-blue-primary">Time to move</h2>
                <p className="my-2 text-base-lg">
                  Although your car might be able to tell you when it's done charging, it's easy to
                  forget checking up, we will send you a gentle reminder if you're running behind.
                </p>
            </div>
            <div className="lg:w-1/3">
                <h2 className="text-3xl font-bold text-relay-blue-primary">Report concerns</h2>
                <p className="my-2 text-base-lg">
                  Report problems with malfunctioning equipment, bad actors or other concerns back to 
                  the destination staff.
                </p>
            </div>
          </section>
      </div>
    </section>
  )
}

const PrviacySection = () => {
  return(
    <section className="mt-5 lg:mt-20">
      <div className="flex flex-col mx-4 lg:max-w-screen-md lg:mx-auto">
        <h1 className="mb-4 text-4xl font-extrabold lg:text-5xl text-relay-text">Privacy at heart</h1>
        <p className="text-base font-semibold lg:text-2xl">Your details are never seen by other drivers or the venue.</p>
        <p className="text-base font-semibold lg:text-2xl">We anonymously convey messages to everyone waiting to charge.</p>
      </div>
      <div className="flex flex-col max-w-full mt-8 lg:max-w-screen-lg lg:mx-auto">
        <StaticImage 
          placeholder="tracedSVG"
          src="../images/privacy.png" 
          alt="Your destination with peace of mind for vehicle charging"/>
      </div>
      <div className="py-10 text-black bg-relay-blue-background lg:py-14">
        <section className="flex flex-col max-w-screen-lg mx-4 lg:mx-auto lg:flex-row">
            <div className="mb-8 lg:w-1/3 lg:mr-8 lg:mb-0">
                <h2 className="text-3xl font-bold text-relay-blue-primary">All under wraps</h2>
                <p className="my-2 text-base-lg">
                    On arrival simply scan the QR code displayed at the the connector. 
                    We securely store your details with us and ensure it’s never shared with other drivers.
                </p>
            </div>
            <div className="mb-8 lg:w-1/3 lg:mr-8 lg:mb-0">
              <h2 className="text-3xl font-bold text-relay-blue-primary">Encrypted at rest</h2>
                <p className="my-2 text-base-lg">
                    We keep you posted via SMS when the charger becomes available.
                    No apps or signups necessary. Just scan and enjoy your time at the destination.
                </p>
            </div>
            <div className="lg:w-1/3">
                <h2 className="text-3xl font-bold text-relay-blue-primary">GDPR</h2>
                <p className="my-2 text-base-lg">
                  We're GDPR compliant. Your personal data only belongs to you. Relay provides
                  an anonymous wait list without compromising on privacy.
                </p>
            </div>
          </section>
      </div>
    </section>
  )
}

const ShareTheLoveSection = () => {
  return(
    <section className="mt-5 lg:mt-20">
      <div className="flex flex-col mx-4 lg:max-w-screen-md lg:mx-auto">
        <h1 className="mb-4 text-4xl font-extrabold lg:text-5xl text-relay-text">Share the love</h1>
        <p className="text-base font-semibold lg:text-2xl">Making it easier to charge efficiently at destinations,</p>
        <p className="text-base font-semibold lg:text-2xl">by breaking the communication barrier.</p>
      </div>
      <div className="flex flex-col max-w-full mt-8 lg:max-w-screen-lg lg:mx-auto">
        <StaticImage 
          placeholder="tracedSVG"
          src="../images/friendly-01.png" 
          alt="Your destination with peace of mind for vehicle charging"/>
      </div>
      <div className="py-10 text-black bg-relay-blue-background lg:py-14">
        <section className="flex flex-col max-w-screen-lg mx-4 lg:mx-auto lg:flex-row">
            <div className="mb-8 lg:w-1/3 lg:mr-8 lg:mb-0">
                <h2 className="text-3xl font-bold text-relay-blue-primary">Community</h2>
                <p className="my-2 text-base-lg">
                  These are the early days of electric transport. As an EV adopter you've
                  made a commitment towards sustainable transport. Timing the charges 
                  gives you a chance to meet other like minded people.
                </p>
            </div>
            <div className="mb-8 lg:w-1/3 lg:mr-8 lg:mb-0">
              <h2 className="text-3xl font-bold text-relay-blue-primary">Frictionless</h2>
                <p className="my-2 text-base-lg">
                  Charging your car is a necessity not a focus. We mediate all conversations 
                  to enhance your experience from a privacy and customer service perspective. 
                </p>
            </div>
            <div className="lg:w-1/3">
                <h2 className="text-3xl font-bold text-relay-blue-primary">Effortless</h2>
                <p className="my-2 text-base-lg">
                  Your destination is a temporary stop, so we focused on building technology
                  that does not require you to install an app or sign up for accounts. We built it
                  using the simplest ingredients.
                </p>
            </div>
          </section>
      </div>
    </section>
  )
}

const ForVehicleOwnersPage = () => {
  return (
    <Layout>
      <Seo 
        title="For Vehicle Owners" 
        description="How does it work for EV owners"
      />
      <GetNotifiedSection/>
      <PrviacySection/>
      <ShareTheLoveSection/>
    </Layout>
  )
}

export default ForVehicleOwnersPage
